// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-gallery-tsx": () => import("./../../../src/templates/gallery.tsx" /* webpackChunkName: "component---src-templates-gallery-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-thumbnails-tsx": () => import("./../../../src/templates/thumbnails.tsx" /* webpackChunkName: "component---src-templates-thumbnails-tsx" */),
  "component---src-templates-topic-description-tsx": () => import("./../../../src/templates/topic-description.tsx" /* webpackChunkName: "component---src-templates-topic-description-tsx" */),
  "component---src-templates-topics-tsx": () => import("./../../../src/templates/topics.tsx" /* webpackChunkName: "component---src-templates-topics-tsx" */)
}

